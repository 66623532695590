import { POINTS_IN_EURO } from "src/constants/specs"

export const convertBytesToHumanReadable = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())

  return Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + ' ' + sizes[i]
}

export const lowerFirstLetter = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export const upperFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const convertMinutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  return {
    hours,
    minutes: remainingMinutes
  }
}

export const constFormatUrl = (url: string) => {
  return url.indexOf('://') === -1 ? `https://${url}` : url
}

export const toCamelCase = (str: string) => {
  const charactersToReplace = /[-_]+(.)?/g

  return str.replace(charactersToReplace, (_, character) =>
    character ? character.toUpperCase() : ''
  )
}

export const pointsToMoney = (pts: number) => {
  return pts * POINTS_IN_EURO;
}

export const numberDotToComma = (val: number) => {
  return val.toString().replace('.', ',');
}
