import { FC, Fragment } from 'react';
import { Box } from '@mui/material';
import { Marker } from 'react-leaflet';
import { LatLngLiteral, divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { styled } from '@mui/system';
import { Pin, PinTree, PinTreeInner } from '../../../assets/images';
import { christmas } from 'src/utils/christmasTime';
import theme from 'src/theme/index';

type Props = React.PropsWithChildren & {
  position: LatLngLiteral;
  imageUrl: string;
};

const LocationMarker: FC<Props> = (props) => {
  const { position, imageUrl } = props;

  const iconHeight = christmas() ? 108 : 80;
  const iconWidth = christmas() ? 53 : 60;
  const borderSize = 4;
  const pin = christmas() ? PinTree : Pin;

  const NewMarkerIcon = styled('div')({
    width: `${iconWidth}px`,
    height: `${iconHeight}px`,
    backgroundColor: christmas() ? theme.palette.secondary.main : '#fff',
    maskImage: `url(${pin})`,
    maskSize: 'contain',
    maskPosition: 'center',
    maskRepeat: 'no-repeat',
    '&::after': {
      position: 'absolute',
      content: '""',
      top: `${borderSize}px`,
      left: `${borderSize}px`,
      width: `${iconWidth - borderSize * 2}px`,
      height: `${iconHeight - borderSize * 2}px`,
      backgroundImage: `url("${imageUrl}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      maskImage: `url(${christmas() ? PinTreeInner : Pin})`,
      maskSize: 'contain',
      maskPosition: 'center',
      maskRepeat: 'no-repeat',
    },
  });

  const iconMarkup = renderToStaticMarkup(<NewMarkerIcon></NewMarkerIcon>);
  const customMarkerIcon = divIcon({
    html: `<div style="height: 100%">${iconMarkup}</div>`,
    iconSize: [iconWidth, iconHeight],
    iconAnchor: [iconWidth / 2, iconHeight],
    className: 'usr-marker',
  });

  return (
    <Fragment>
      <Box>
        <NewMarkerIcon></NewMarkerIcon>
        <Marker icon={customMarkerIcon} position={position}></Marker>
      </Box>
    </Fragment>
  );
};

export default LocationMarker;
