import { combineReducers, configureStore } from '@reduxjs/toolkit';
import showplacesReducer from 'src/components/showplaces/reducer/showplacesSlice'
import highscoresReducer from 'src/components/showplaces/reducer/highscoresSlice'
import appReducer from 'src/app/slices/app'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { setupListeners } from '@reduxjs/toolkit/query'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  showplacesMin: showplacesReducer,
  highscore: highscoresReducer,
  app: appReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
// defaults to localStorage for web
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});
export const persister = persistStore(store)

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;