import { FC, Fragment } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ButtonSnowBorder from 'src/components/christmasTheme/ButtonSnowBorder';
import { christmas } from 'src/utils/christmasTime';
import { t } from 'i18next';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CountUp from 'react-countup';
import InfoBarChristmasImages from 'src/components/christmasTheme/InfoBarChristmasImages';
import palette from 'src/theme/palette';

type Props = React.PropsWithChildren & {
  onNext: () => void;
  onGuess: () => void;
  imageButtonClicked: () => void;
  progress: { round: number | undefined; roundMax: number | undefined };
  points: number;
  positioned: boolean;
  checked: boolean;
  animating: boolean;
  imageUrl: string;
};

const InfoBar: FC<Props> = (props) => {
  const { onNext, onGuess, progress, points, positioned, checked, animating, imageUrl, imageButtonClicked } = props;
  const theme = useTheme();

  return (
    <Fragment>
      <Box
        sx={{
          position: 'absolute',
          width: '90%',
          bottom: '5%',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: theme.zIndex.drawer,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: 2,
        }}
      >
        <Box display={{ xs: 'block', md: 'none' }}>
          <Button
            color="primary"
            onClick={imageButtonClicked}
            sx={{
              backgroundColor: '#fff',
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary.main}`,
              minWidth: 0,
              padding: 1,
              '&:hover': {
                bgcolor: '#fff',
              },
            }}
          >
            <ImageSearchIcon sx={{ opacity: 0 }} />
          </Button>
        </Box>
        <Box
          sx={{
            minWidth: 'min(50%, 250px)',
          }}
        >
          <ButtonSnowBorder topRight>
            {christmas() && <InfoBarChristmasImages></InfoBarChristmasImages>}
            <Box
              px={3}
              py={1}
              color={christmas() ? theme.palette.secondary.contrastText : 'inherit'}
              sx={{
                background: christmas() ? '#345E45' : '#fff' /*`${theme.palette.secondary.main}`,*/,
                borderRadius: 4,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 2,
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: '20px',
                  fontSize: '20px',
                  lineHeight: '48px',
                  fontWeight: 300,
                  '& span': { color: christmas() ? 'undefined' : palette.black },
                }}
              >
                <Typography fontWeight={300}>
                  {t('common.step')}{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {progress.round}/{progress.roundMax}
                  </span>
                </Typography>
                <Typography fontWeight={300}>
                  <span style={{ fontWeight: 'bold' }}>
                    <CountUp preserveValue={true} end={points}></CountUp>
                  </span>{' '}
                  {t('common.points')}
                </Typography>
              </Box>
            </Box>
          </ButtonSnowBorder>
        </Box>
        <ButtonSnowBorder hideMobile>
          {!checked ? (
            <>
              <Button
                onClick={onGuess}
                disabled={!positioned || checked || animating}
                variant="contained"
                sx={{
                  minWidth: 0,
                  [theme.breakpoints.down('md')]: { padding: 1 },
                }}
              >
                <KeyboardArrowRightIcon />
                <Typography
                  sx={{ display: { xs: 'none', md: 'block' }, textTransform: 'lowercase', fontWeight: '600' }}
                >
                  {t('action.guess')}
                </Typography>
              </Button>
            </>
          ) : (
            <Button
              onClick={onNext}
              disabled={!checked}
              variant="contained"
              sx={{ minWidth: 0, [theme.breakpoints.down('md')]: { padding: 1 } }}
            >
              <KeyboardArrowRightIcon />
              <Typography sx={{ display: { xs: 'none', md: 'block' }, textTransform: 'lowercase', fontWeight: '600' }}>
                {t('action.next')}
              </Typography>
            </Button>
          )}
        </ButtonSnowBorder>
      </Box>
    </Fragment>
  );
};

export default InfoBar;
