import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import palette from 'src/theme/palette';
import { Highscore } from 'src/assets/images';
import HeadingDecoration from 'src/components/decorativeElements/HeadingDecoration';
import ButtonSnowBorder from 'src/components/christmasTheme/ButtonSnowBorder';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import ShareIcon from '@mui/icons-material/Share';
import theme from 'src/theme/index';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { fetchHighscoresAsync } from '../reducer/highscoresSlice';
import { christmas } from 'src/utils/christmasTime';

type Props = React.PropsWithChildren & {
  onPlayAgain: () => void;
  currentScoreId: null | number;
};

const GratulationView: FC<Props> = (props) => {
  const { onPlayAgain, currentScoreId, children } = props;
  const { data: highscore, status, error } = useAppSelector((state) => state.highscore);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [shared, setShared] = useState('');

  const shareData = christmas()
    ? {
        title: t('home.gameTitleChristmas'),
        text: t('home.shareInfoChristmas'),
        url: 'https://getdoyo.app/xmas2023',
      }
    : {
        title: t('home.gameTitle'),
        text: t('home.shareInfo'),
        url: 'https://getdoyo.app/geo-guessing',
      };

  const shareAlert = () => {
    navigator.clipboard.writeText(shareData.url);
    setShared(t('action.copyUrl'));

    setTimeout(() => {
      setShared('');
    }, 3000);
  };

  const share = async () => {
    if (!navigator.canShare) {
      shareAlert();
      return;
    }
    try {
      await navigator.share(shareData);
    } catch {
      shareAlert();
    }
  };

  const position = useMemo(() => {
    if (!currentScoreId) return null;
    return highscore.findIndex((o) => o.id === currentScoreId.toString()) + 1;
  }, [currentScoreId, status]);

  useEffect(() => {
    setLoading(true);
    if (status === 'idle') {
      dispatch(fetchHighscoresAsync());
    }
    if (status === 'succeeded') {
      setLoading(false);
    }
  }, [status, dispatch]);

  return (
    <Fragment>
      {christmas() ? (
        <Box sx={{ maxWidth: '700px' }}>
          <img style={{ maxWidth: '100%' }} src={Highscore} alt="hohoho" loading="lazy" draggable="false" />
        </Box>
      ) : (
        <Box sx={{ paddingTop: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box>
            <svg width="85" height="23" viewBox="0 0 85 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 20C9.12211 19.7469 5.26306 16.3271 3 15"
                stroke="#ADE5BA"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path d="M58.9033 3L56.0003 11.519" stroke="#ADE5BA" strokeWidth="5" strokeLinecap="round" />
              <path
                d="M75 20C75.1886 19.3573 81.5234 14.2707 82 14"
                stroke="#ADE5BA"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                d="M34.3182 12.2682C33.8192 11.8762 31.6079 3.92237 31.5402 3.35936"
                stroke="#ADE5BA"
                strokeWidth="5"
                strokeLinecap="round"
              />
            </svg>
          </Box>
          <Box>
            <Typography sx={{ fontSize: '48px', fontWeight: 600, color: '#000' }}>{t('highscore.title')}</Typography>
          </Box>
        </Box>
      )}
      <Stack
        textAlign={'center'}
        sx={{
          backgroundColor: 'transparent',
          [theme.breakpoints.down('lg')]: {
            flexGrow: 1,
          },
        }}
      >
        {loading ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight="40vh">
            <CircularProgress></CircularProgress>
          </Box>
        ) : (
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} height={'100%'}>
            {error && <Typography>Es gibt leider ein Problem</Typography>}
            <HeadingDecoration>
              <Typography
                sx={{ fontSize: '24px' }}
                fontWeight={600}
                color={christmas() ? palette.primary.contrastText : palette.text}
              >
                {position ? (
                  <>
                    <Trans
                      i18nKey="highscore.subheadlineInTop50"
                      values={{
                        position: position,
                      }}
                      components={{
                        b: <b />,
                      }}
                    />
                  </>
                ) : (
                  <>{t('highscore.subheadline')}</>
                )}
              </Typography>
            </HeadingDecoration>
            <Box
              sx={{
                color: christmas() ? '#fff' : 'inherit',
                borderRadius: '16px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                flexGrow: 1,
                flexBasis: { xs: '30vh', md: '40vh' },
                overflow: 'hidden',
              }}
              p={3}
            >
              <List
                sx={{
                  listStyle: 'decimal',
                  pl: 4,
                  pr: 2,
                  pb: 4,
                  overflowY: 'auto',
                  width: 'min(100%, 400px)',
                  WebkitMaskImage:
                    '-webkit-gradient(linear, left 80%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
                }}
              >
                {highscore.map((item, index) => (
                  <ListItem
                    key={item.id}
                    sx={{ display: 'list-item', fontWeight: position && index === position - 1 ? 'bold' : 'normal' }}
                    disablePadding
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: '5px',
                      }}
                    >
                      <ListItemText primary={<Typography fontWeight="inherit">{item.attributes.name}</Typography>} />
                      <ListItemText
                        sx={{ flexGrow: 0, minWidth: '6ch', textAlign: 'right' }}
                        primary={<Typography fontWeight="inherit">{item.attributes.score}</Typography>}
                      />
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 'min(100%, 400px)',
                rowGap: 1,
                [theme.breakpoints.down('md')]: { flexDirection: 'column', alignItems: 'center', mb: 1 },
              }}
            >
              <ButtonSnowBorder>
                <Button
                  className="geo-button-start"
                  variant="contained"
                  color="primary"
                  onClick={onPlayAgain}
                  sx={{ textTransform: 'none', fontWeight: 'bold' }}
                >
                  {t('action.playAgain')}
                </Button>
              </ButtonSnowBorder>
              <Button
                component="label"
                startIcon={<ShareIcon />}
                onClick={share}
                sx={{ color: christmas() ? undefined : 'primary.contrastText' }}
              >
                {t('common.shareWithFriends')}
              </Button>
              {shared !== '' && (
                <Box sx={{ position: 'absolute', top: '10px', left: 0, width: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Alert severity="info">{shared}</Alert>
                  </Box>
                </Box>
              )}
            </Box>
            {children}
          </Box>
        )}
      </Stack>
    </Fragment>
  );
};

export default GratulationView;
