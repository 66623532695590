import { Box } from '@mui/material';
import theme from 'src/theme/index';
import { christmas } from 'src/utils/christmasTime';

type Props = React.PropsWithChildren & {
  topRight?: boolean;
  fullWidth?: boolean;
  hideMobile?: boolean;
};

const ButtonSnowBorder: React.FC<Props> = ({ topRight, fullWidth, hideMobile, children }) => {
  return (
    <Box sx={{ position: 'relative', display: 'flex', width: fullWidth ? '100%' : 'auto' }}>
      {children}
      {christmas() && (
        <Box
          sx={{
            position: 'absolute',
            [theme.breakpoints.down('md')]: { display: hideMobile ? 'none' : 'block' },
            top: -4,
            left: topRight ? 'auto' : -2,
            right: topRight ? -2 : 'auto',
            pointerEvents: 'none',
          }}
        >
          {topRight ? (
            <>
              <svg width="102" height="19" viewBox="0 0 102 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M101.91 13.5491C101.91 13.5491 101.6 16.2791 100.98 17.5591C100.31 18.9491 99.47 18.4091 99.17 16.0591C98.28 9.11908 91.74 5.96908 85.82 5.96908C81.78 5.96908 67.72 7.36006 64.5 7.50006C61.28 7.64006 48.22 5.00006 31.5 5.00006C25.78 4.45006 19.42 4.00911 17 4.00911C-10.55 4.00911 3.87 4.00911 3.87 4.00911C3.85 3.25911 9.37 3.50911 13.37 3.00911C17.37 2.50911 22.63 1.50908 27 1.52908C31.37 1.54908 36.37 1.00913 43.5 1.52909C50.63 2.04905 70.26 -0.110918 76.06 0.019082C81.43 0.149082 83.23 2.13908 87.58 1.99908C91.93 1.85908 95.83 1.80908 97.65 2.64908C101.83 4.59908 102.23 8.88908 101.91 13.5491Z"
                  fill="white"
                />
              </svg>
            </>
          ) : (
            <>
              <svg width="73" height="19" viewBox="0 0 73 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.0858486 13.549C0.0858486 13.549 0.395849 16.279 1.01585 17.559C1.68585 18.949 2.52585 18.409 2.82585 16.059C3.71585 9.11899 10.2558 5.96899 16.1758 5.96899C20.2158 5.96899 21.7759 8.36903 24.9958 8.50903C28.2158 8.64903 29.9958 5.14899 46.7159 5.14899C52.4359 4.59899 61.5759 4.00902 63.9958 4.00902C66.4158 4.00902 72.9958 4.00902 72.9958 4.00902C73.0158 3.25902 67.4958 3.50902 63.4958 3.00902C59.4958 2.50902 54.2358 1.50899 49.8658 1.52899C45.4958 1.54899 40.2558 1.56903 37.9958 1.00903C35.7359 0.449033 31.7358 -0.111009 25.9358 0.0189907C20.5658 0.148991 18.7658 2.13899 14.4158 1.99899C10.0658 1.85899 6.16585 1.80899 4.34585 2.64899C0.165849 4.59899 -0.234151 8.88899 0.0858486 13.549Z"
                  fill="white"
                />
              </svg>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ButtonSnowBorder;
