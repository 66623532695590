import { Box, Typography, Zoom } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import HighscoreSubmitView from './HighscoreSubmitView';
import HighscoreView from './HighscoreView';
import palette from 'src/theme/palette';
import { fetchHighscoreSumAPI } from 'src/api/graphql/highscores.API';
import { pointsToMoney } from 'src/utils/format';
import { t } from 'i18next';
import { useAppDispatch } from 'src/app/hooks';
import { resetGeoCoordinates } from 'src/app/slices/app';

type Props = React.PropsWithChildren & {
  onReset: () => void;
  onPlayAgain: () => void;
};

const FinishView: FC<Props> = (props) => {
  const { onPlayAgain } = props;
  const [earned, setEarned] = useState(0);
  const [showHighscore, setShowHighscore] = useState(false);
  const [currentScoreId, setCurrentScoreId] = useState<null | number>(null);
  const dispatch = useAppDispatch();

  const playAgain = () => {
    setShowHighscore(false);
    onPlayAgain();
  };

  const handleSuccess = (id: string) => {
    setShowHighscore(true);
    setCurrentScoreId(Number(id));
    dispatch(resetGeoCoordinates());
  };

  const submittedScoreId = useMemo(() => {
    return currentScoreId;
  }, [currentScoreId]);

  const getSum = async () => {
    try {
      const res = await fetchHighscoreSumAPI();
      const score = pointsToMoney(res);
      setEarned(score);
    } catch (err) {
      console.error('Failed to get HighscoreSum: ', err);
    } finally {
    }
  };

  useEffect(() => {
    getSum();
  }, [showHighscore]);

  return (
    <Box
      sx={{
        height: '100%',
        paddingBottom: { xs: '80px', md: '120px' },
      }}
    >
      <Zoom in={showHighscore} mountOnEnter unmountOnExit timeout={1000}>
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              rowGap: 3,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HighscoreView onPlayAgain={playAgain} currentScoreId={submittedScoreId}>
              {earned >= 500 && (
                <Box textAlign={'center'} mt={{ md: 3 }}>
                  <Typography color={palette.primary.contrastText} fontWeight={300} variant="textMedium">
                    {t('highscore.goalAchieved')}
                  </Typography>
                </Box>
              )}
            </HighscoreView>
          </Box>
        </Box>
      </Zoom>
      <Zoom in={!showHighscore} mountOnEnter unmountOnExit timeout={{ appear: 1000, exit: 0 }}>
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HighscoreSubmitView onPlayAgain={playAgain} onSuccess={handleSuccess}></HighscoreSubmitView>
          </Box>
        </Box>
      </Zoom>
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, opacity: 0 }}>
        <Typography>{`${earned}€`}</Typography>
      </Box>
    </Box>
  );
};

export default FinishView;
