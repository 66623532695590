import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchShowplacesAPI, createShowplaceAPI, updateShowplaceAPI, deleteShowplaceAPI, fetchShowplacesMinAPI, fetchShowplaceByIdAPI } from 'src/api/graphql/showplaces.API';
import { ShowplaceState, ICreateShowplace, IUpdateShowplace } from 'src/typings/interfaces/showplace.interfaces';

export const fetchShowplacesAsync = createAsyncThunk('showplaces/fetchShowplaces', async () => {
  return await fetchShowplacesAPI();
});

export const fetchShowplacesMinAsync = createAsyncThunk('showplaces/fetchShowplacesMin', async (amount: number) => {
  return await fetchShowplacesMinAPI(amount);
});

export const fetchShowplaceByIdAsync = createAsyncThunk('showplaces/fetchShowplaceById', async (id: string) => {
  return await fetchShowplaceByIdAPI(id);
});

export const createShowplaceAsync = createAsyncThunk('showplaces/createShowplace', async (newShowplace: ICreateShowplace) => {
  return await createShowplaceAPI(newShowplace);
});

export const updateShowplaceAsync = createAsyncThunk('showplaces/updateShowplace', async (showplaceToUpdate: IUpdateShowplace) => {
  return await updateShowplaceAPI(showplaceToUpdate);
});

export const deleteShowplaceAsync = createAsyncThunk('showplaces/deleteShowplace', async (showplaceId: string) => {
  return await deleteShowplaceAPI(showplaceId);
});

const initialState: ShowplaceState = {
  data: [],
  status: 'idle',
  error: null,
};

export const showplacesSlice = createSlice({
  name: 'showplacesMin',
  initialState,
  reducers: {
    resetShowplacesMin: (state) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShowplacesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShowplacesAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchShowplacesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchShowplacesMinAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShowplacesMinAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchShowplacesMinAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchShowplaceByIdAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShowplaceByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchShowplaceByIdAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createShowplaceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createShowplaceAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.push(action.payload);
      })
      .addCase(createShowplaceAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateShowplaceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateShowplaceAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex((showplace) => showplace.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      })
      .addCase(updateShowplaceAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteShowplaceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteShowplaceAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex((showplace) => showplace.id === action.payload.id);
        if (index !== -1) {
          state.data.splice(index, 1);
        }
      })
      .addCase(deleteShowplaceAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetShowplacesMin } =
  showplacesSlice.actions

export default showplacesSlice.reducer;
