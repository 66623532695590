import { FC, ReactNode, Fragment } from 'react';
import { Box, Container } from '@mui/material';

interface AppBarLayoutProps {
  children: ReactNode;
}

const AppBarLayout: FC<AppBarLayoutProps> = ({ children }) => {
  return (
    <Fragment>
      <Container maxWidth={false} disableGutters style={{ height: '100%' }}>
        <Box sx={{ height: '100%', maxWidth: '100%' }}>{children}</Box>
      </Container>
    </Fragment>
  );
};

export default AppBarLayout;
