import { MistleStarWhite, CircleSnowBorder, TreeTwo } from 'src/assets/images';
import { Backdrop, Box, Button, Grid, Zoom, styled } from '@mui/material';
import theme from 'src/theme/index';
import { t } from 'i18next';
import { FC } from 'react';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ButtonSnowBorder from 'src/components/christmasTheme/ButtonSnowBorder';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ShowplaceCounter from './ShowplaceCounter';
import { christmas } from 'src/utils/christmasTime';
type Props = React.PropsWithChildren & {
  imageFullScreen: boolean;
  showplaceImageUrl: string;
  modalOpen: boolean;
  modalClick: () => void;
  toggleImageFullScreen: () => void;
};

const ShowplaceImageComponent: FC<Props> = (props) => {
  const { imageFullScreen, showplaceImageUrl, modalOpen, modalClick, toggleImageFullScreen } = props;
  const ComponentWithSx = styled(ButtonSnowBorder)({});

  return (
    <>
      {/* desktop */}
      <Grid
        item
        display={{ xs: 'none', md: 'block' }}
        xs={12}
        md={imageFullScreen ? 12 : 6}
        style={{ zIndex: theme.zIndex.drawer + 2, transition: 'all 500ms ease-in-out', height: '100%' }}
      >
        <>
          <Box
            component="img"
            sx={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
            src={showplaceImageUrl}
            alt="geheim"
            draggable="false"
          ></Box>

          <Button
            onClick={toggleImageFullScreen}
            sx={{
              position: 'absolute',
              background: '#fff',
              left: '10px',
              top: '10px',
              zIndex: theme.zIndex.drawer,
              minWidth: 0,
              padding: 1,
              boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary.main}`,
              '&:hover': {
                bgcolor: '#fff',
              },
            }}
          >
            {imageFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </Button>

          <ShowplaceCounter imageFullScreen={imageFullScreen} showplaceImageUrl={showplaceImageUrl}></ShowplaceCounter>
        </>
      </Grid>
      {/* mobile */}
      <Backdrop
        sx={{
          display: { xs: 'flex', md: 'none' },
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        open={modalOpen}
      >
        <Zoom in={modalOpen} unmountOnExit style={{ transformOrigin: 'bottom left' }}>
          <Box
            sx={{
              minHeight: '50vh',
              p: 2,
              width: '100%',
            }}
            onClick={modalClick}
          >
            <Box
              sx={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                rowGap: 4,
                p: 3,
                borderRadius: 4,
                backgroundColor: christmas() ? '#345E45' : '#fff',
                backgroundImage: christmas()
                  ? `radial-gradient(circle at center 40%,rgba(6,53,25,0.17),rgba(6,53,25,0.17) 80%,transparent 80%),
                  radial-gradient(circle at center 40%,rgba(6,53,25,0.17) ,rgba(6,53,25,0.17) 70%,transparent 70%),
                  radial-gradient(circle at center 40%,rgba(6,53,25,0.17) ,rgba(6,53,25,0.17) 60%,transparent 60%),
                  radial-gradient(circle at center 40%,rgba(6,53,25,0.17) ,rgba(6,53,25,0.17) 50%,transparent 50%)`
                  : '',
              }}
            >
              {christmas() && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 'calc(100% - 15px)',
                    left: '50%',
                    transform: 'translate(-50%)',
                  }}
                  component="img"
                  src={MistleStarWhite}
                  alt="dekoratives Element"
                  draggable="false"
                ></Box>
              )}
              <Box sx={{ position: 'relative' }}>
                {christmas() && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-5px',
                      left: '50%',
                      transform: 'translate(-50%)',
                      width: '73%',
                      height: '19%',
                    }}
                    component="img"
                    src={CircleSnowBorder}
                    alt="dekoratives Element"
                    draggable="false"
                  ></Box>
                )}
                <a href={showplaceImageUrl} target="_blank" rel="noreferrer">
                  <Box
                    component="img"
                    sx={{
                      height: `calc(100vw - ${theme.spacing(10)})`,
                      width: `calc(100vw - ${theme.spacing(10)})`,
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                    src={showplaceImageUrl}
                    alt="geheim"
                    draggable="false"
                  ></Box>
                </a>
              </Box>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <ComponentWithSx topRight sx={{ width: '100%' }} fullWidth>
                  {christmas() && (
                    <Box
                      sx={{ position: 'absolute', bottom: '100%', right: '-15px', width: '30%' }}
                      component="img"
                      src={TreeTwo}
                      alt="dekoratives Element"
                      draggable="false"
                    ></Box>
                  )}
                  <Button
                    className="geo-button-start"
                    variant="contained"
                    color="primary"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 'bold',
                      width: '100%',
                    }}
                    startIcon={<ImageSearchIcon />}
                  >
                    {t('action.findShowplace')}
                  </Button>
                </ComponentWithSx>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Backdrop>
    </>
  );
};

export default ShowplaceImageComponent;
