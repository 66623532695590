import { graphqlClient } from '../client';
import { createHighscoreMutation, fetchHighscoreSumQuery, fetchHighscoresQuery } from './highscores.queries';
import { ICreateHighscore } from 'src/typings/interfaces';

export const fetchHighscoresAPI = async () => {
  const response = await graphqlClient.post('', { query: fetchHighscoresQuery });
  return response.data.data.highscores.data;
};

export const fetchHighscoreSumAPI = async () => {
  const response = await graphqlClient.post('', { query: fetchHighscoreSumQuery });
  return response.data.data.highscoreSum.sum;
};

export const createHighscoreAPI = async (newHighscore: ICreateHighscore) => {
  const response = await graphqlClient.post('', {
    query: createHighscoreMutation,
    variables: {
      data: newHighscore,
    },
  });

  return response.data.data.createHighscore;
};
