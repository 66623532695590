import { Box, CircularProgress, Typography, Zoom } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FULLSCREEN_IMAGE_TIME } from 'src/constants/specs';

type Props = React.PropsWithChildren & {
  showplaceImageUrl: string;
  imageFullScreen: boolean;
};

const ShowplaceCounter: FC<Props> = (props) => {
  const { showplaceImageUrl, imageFullScreen } = props;
  const defaultTimer = FULLSCREEN_IMAGE_TIME / 1000;
  const [counter, setCounter] = useState<number>(defaultTimer);
  const circleSize = 14;

  useEffect(() => {
    setCounter(defaultTimer);
  }, [showplaceImageUrl]);

  useEffect(() => {
    if (counter === 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [counter]);

  return (
    <Zoom in={counter > 0 && imageFullScreen}>
      <Box
        sx={{
          position: 'absolute',
          left: `calc(50% - ${circleSize / 2}vh)`,
          top: '20px',
        }}
      >
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            size={`${circleSize}vh`}
            thickness={4}
            sx={{
              color: '#fff',
              background: 'rgba(0,0,0, 0.15)',
              borderRadius: '100%',
              boxShadow: 'inset 0px 0px 0px 10px rgba(255,255,255,0.5);',
            }}
            value={(100 / defaultTimer) * (defaultTimer - counter)}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              fontSize={`${circleSize / 2 + 1}vh`}
              lineHeight={`${circleSize / 2 + 1}vh`}
              color={'#fff'}
              fontWeight={'bold'}
            >
              {counter}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Zoom>
  );
};

export default ShowplaceCounter;
