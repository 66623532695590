import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Grid, Slide, Typography } from '@mui/material';
import { fetchShowplacesMinAsync } from '../reducer/showplacesSlice';
import { IShowplaceMin, IShowplaceSolution } from 'src/typings/interfaces';
import { LeafletMap } from 'src/components/leafletMap';
import { AMOUNT_DEFAULT, FULLSCREEN_IMAGE_TIME } from 'src/constants/specs';
import ShowplaceImageComponent from '../components/ShowplaceImageComponent';
import FinishView from './FinishView';
import { fetchShowplaceByIdAPI } from 'src/api/graphql/showplaces.API';
import ShowplaceInfo from '../components/ShowplaceInfo';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { setGeoCoordinates, resetGeoCoordinates, setScorePoints } from 'src/app/slices/app';
import GameInstructions from '../components/GameInstructions';
import palette from 'src/theme/palette';
import { christmas } from 'src/utils/christmasTime';
type Props = React.PropsWithChildren & {
  roundTracking?: number;
};
const Showplaces: FC<Props> = (props) => {
  const { roundTracking = 0 } = props;

  const [round, setRound] = useState(roundTracking);
  const [loading, setLoading] = useState(false);
  const [playAgain, setPlayAgain] = useState(false);
  const [finished, setFinished] = useState(roundTracking === AMOUNT_DEFAULT);
  const [showplaceSolution, setShowplaceSolution] = useState<IShowplaceSolution | null>(null);
  const [imageFullScreen, setImageFullScreen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { data: showplacesList, status: statusMin } = useAppSelector((state) => state.showplacesMin);

  const ApiUrl = process.env.REACT_APP_API_ENDPOINT;

  const handleReset = () => {
    setRound(0);
    dispatch(setScorePoints(0));
    setShowplaceSolution(null);
    dispatch(resetGeoCoordinates());
  };

  const startGame = () => {
    handleReset();
    setFinished(false);
    dispatch(fetchShowplacesMinAsync(AMOUNT_DEFAULT));
    setPlayAgain(!playAgain);
    timerImage();
  };

  const currentShowplace = useMemo<IShowplaceMin | null>(() => {
    return showplacesList && showplacesList[round] ? showplacesList[round] : null;
  }, [round, showplacesList]);

  const showplaceImageUrl = useMemo(() => {
    if (!currentShowplace) return '';
    const images = currentShowplace?.attributes.images.data;
    const rand = Math.floor(Math.random() * images.length);
    const url = images[rand].attributes.url;
    return url;
  }, [currentShowplace]);

  const handleNext = () => {
    setShowplaceSolution(null);
    if (showplacesList && round >= showplacesList?.length - 1) {
      setFinished(true);
      return;
    }
    setRound(round + 1);
  };

  const handleGuess = async (position: any) => {
    try {
      if (!currentShowplace) return;
      const res = await fetchShowplaceByIdAPI(currentShowplace?.id);
      setShowplaceSolution(res);

      const newGeo = {
        id: currentShowplace.id,
        coordinates: `${position.lat}, ${position.lng}`,
      };
      dispatch(setGeoCoordinates(newGeo));
    } catch (err) {
      console.error('Failed: ', err);
    } finally {
    }
  };

  useEffect(() => {
    setLoading(true);

    if (statusMin === 'idle') {
      dispatch(fetchShowplacesMinAsync(AMOUNT_DEFAULT));
    }
    if (statusMin === 'succeeded') {
      setLoading(false);
    }
    if (statusMin === 'loading') {
      setLoading(true);
    }
  }, [statusMin, dispatch]);

  const timerImage = () => {
    setImageFullScreen(true);
    setModalOpen(true);
    const timer = setTimeout(() => {
      setImageFullScreen(false);
    }, FULLSCREEN_IMAGE_TIME);
    return () => clearTimeout(timer);
  };
  useEffect(() => {
    timerImage();
  }, [round]);

  if (statusMin === 'failed') {
    return (
      <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography color={palette.primary.contrastText}>Ups, da ist leider etwas schiefgegangen!</Typography>
      </Box>
    );
  }

  return (
    <Fragment>
      <Box sx={{ height: '100%', backgroundColor: christmas() ? undefined : '#fff' }}>
        {loading ? (
          <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Slide direction="up" in={!finished} mountOnEnter unmountOnExit timeout={500}>
              <Grid container spacing={0} sx={{ position: 'relative', width: '100%', height: '100%' }}>
                <ShowplaceImageComponent
                  imageFullScreen={imageFullScreen}
                  showplaceImageUrl={ApiUrl + showplaceImageUrl}
                  modalOpen={modalOpen}
                  modalClick={() => setModalOpen(false)}
                  toggleImageFullScreen={() => setImageFullScreen(!imageFullScreen)}
                ></ShowplaceImageComponent>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    position: {
                      md: 'absolute',
                    },
                    top: 0,
                    right: 0,
                    zIndex: 1,
                    minWidth: '50%',
                    height: '100%',
                  }}
                >
                  <Box style={{ position: 'relative', height: '100%' }}>
                    {currentShowplace && (
                      <LeafletMap
                        correctPos={currentShowplace?.attributes.geo}
                        imageUrl={ApiUrl + showplaceImageUrl}
                        onNext={handleNext}
                        onGuess={handleGuess}
                        imageButtonClicked={() => setModalOpen(true)}
                        finished={finished}
                        progress={{ round: round + 1, roundMax: showplacesList?.length }}
                      />
                    )}
                  </Box>
                </Grid>
                {showplaceSolution && <ShowplaceInfo showplaceSolution={showplaceSolution}></ShowplaceInfo>}
                <GameInstructions></GameInstructions>
              </Grid>
            </Slide>
            <Slide direction="up" in={finished} mountOnEnter unmountOnExit timeout={1000}>
              <Box
                sx={{
                  height: '100%',
                }}
                p={2}
              >
                <FinishView onReset={handleReset} onPlayAgain={startGame}></FinishView>
              </Box>
            </Slide>
          </>
        )}
      </Box>
    </Fragment>
  );
};

export default Showplaces;
