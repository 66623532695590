import { graphqlClient } from '../client';
import { fetchShowplacesQuery, createShowplaceMutation, updateShowplaceMutation, deleteShowplaceMutation, fetchShowplacesMinQuery, fetchShowplaceByIdQuery } from './showplaces.queries';
import { ICreateShowplace, IUpdateShowplace } from 'src/typings/interfaces';

export const fetchShowplacesAPI = async () => {
  const response = await graphqlClient.post('', { query: fetchShowplacesQuery });
  return response.data.data.showplaces.data;
};

export const fetchShowplacesMinAPI = async (amount: number) => {
  const response = await graphqlClient.post('', { query: fetchShowplacesMinQuery, variables: { amount } });
  return response.data.data.showplacesRandom.showplaces.data;
};

export const fetchShowplaceByIdAPI = async (id: string) => {
  const response = await graphqlClient.post('', {
    query: fetchShowplaceByIdQuery,
    variables: {
      id
    }
  });
  return response.data.data.showplace.data;
};

export const createShowplaceAPI = async (newShowplace: ICreateShowplace) => {
  const response = await graphqlClient.post('', {
    query: createShowplaceMutation,
    variables: {
      input: newShowplace,
    },
  });

  return response.data.data.createShowplace;
};

export const updateShowplaceAPI = async (updatedFields: IUpdateShowplace) => {
  const response = await graphqlClient.post('', {
    query: updateShowplaceMutation,
    variables: {
      input: updatedFields,
    },
  });

  return response.data.data.updateShowplace;
};

export const deleteShowplaceAPI = async (showplaceId: string) => {
  const mutation = deleteShowplaceMutation;

  const response = await graphqlClient.post('', {
    query: mutation,
    variables: {
      id: showplaceId,
    },
  });

  return response.data.data.removeShowplace;
};
