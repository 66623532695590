import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

type Props = React.PropsWithChildren & {
  children: ReactNode;
};

const HeadingDecoration: FC<Props> = (props) => {
  const { children } = props;

  const Line = () => {
    return (
      <Box>
        <svg width="50" height="3" viewBox="0 0 50 3" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M48.5871 0.805858C45.4698 0.991365 42.3251 0.948413 39.2029 0.971615C31.8461 1.02489 24.4924 0.954633 17.1378 0.773874C11.8422 0.643056 6.54729 0.451893 1.25467 0.197543C0.0696185 0.139667 0.0781205 1.98557 1.26318 2.04344C15.8985 2.74959 30.5643 3.02162 45.2129 2.74475C46.3408 2.72408 47.4735 2.72138 48.5984 2.6534C49.7779 2.58281 49.7768 0.737354 48.5899 0.807502L48.5871 0.805858Z"
            fill="white"
          />
        </svg>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: { xs: '5px', md: '20px' } }}>
      <Line />
      {children}
      <Line />
    </Box>
  );
};

export default HeadingDecoration;
