import { Box } from '@mui/material';
import { Trees, Mistle, StarsLeft, StarsRight } from 'src/assets/images';

type Props = React.PropsWithChildren & {};

const InfoBarChristmasImages: React.FC<Props> = ({ children }) => {
  return (
    <Box>
      <Box
        sx={{
          zIndex: 0,
          borderRadius: 4,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'linear-gradient(135deg, rgba(6,53,25,0.17) 0%, rgba(6,53,25,0.17) 17%, rgba(6,53,25,0.34) 17%, rgba(6,53,25,0.34) 33%, rgba(6,53,25,0.5) 33%, rgba(6,53,25,0.5) 50%, rgba(6,53,25,0.67) 50%, rgba(6,53,25,0.67) 67%, rgba(6,53,25,0.84) 67%, rgba(6,53,25,0.84) 84%, rgba(6,53,25,1) 84%, rgba(6,53,25,1) 100%);',
        }}
      ></Box>
      <img
        style={{
          maxWidth: '100%',
          width: '25%',
          position: 'absolute',
          bottom: '100%',
          right: '5%',
          pointerEvents: 'none',
        }}
        draggable="false"
        src={Trees}
        alt="hohoho"
        loading="lazy"
      />
      <img
        style={{
          maxWidth: '100%',
          width: '50%',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%,60%)',
        }}
        src={Mistle}
        alt="hohoho"
        loading="lazy"
        draggable="false"
      />
      <img
        style={{
          maxWidth: '100%',
          position: 'absolute',
          bottom: 4,
          left: 4,
        }}
        src={StarsLeft}
        alt="hohoho"
        loading="lazy"
        draggable="false"
      />

      <img
        style={{
          maxWidth: '100%',
          position: 'absolute',
          top: 4,
          right: 4,
        }}
        src={StarsRight}
        alt="hohoho"
        loading="lazy"
        draggable="false"
      />
    </Box>
  );
};

export default InfoBarChristmasImages;
