import { FC, useState } from 'react';
import { Backdrop, Box, Button, Card, CardContent, Zoom, Typography } from '@mui/material';
import theme from 'src/theme/index';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Instruction_1, Instruction_2, Instruction_3 } from 'src/assets/images';

type Props = React.PropsWithChildren & {
  home?: boolean;
};

const CardContentNoPadding = styled(CardContent)(`
  &:last-child {
    padding-bottom: 0;
  }
`);
const GameInstructions: FC<Props> = (props) => {
  const { home } = props;
  const [showInstruction, setShowInstruction] = useState(false);

  const instructionImages = [Instruction_1, Instruction_2, Instruction_3];

  return (
    <Box>
      {!showInstruction && (
        <Button
          onClick={() => setShowInstruction(!showInstruction)}
          sx={{
            position: 'absolute',
            background: '#fff',
            right: '10px',
            top: '10px',
            zIndex: theme.zIndex.drawer + 1,
            minWidth: 0,
            padding: 1,
            boxShadow: showInstruction ? undefined : `inset 0px 0px 0px 2px ${theme.palette.primary.main}`,
            '&:hover': {
              bgcolor: '#fff',
            },
          }}
        >
          <QuestionMarkIcon />
        </Button>
      )}

      <Backdrop
        sx={{
          display: 'flex',
          color: '#fff',
          zIndex: theme.zIndex.drawer,
          alignItems: 'flex-start',
          justifyContent: home ? 'center' : 'flex-end',
          padding: '10px',
          background: home ? 'transparent' : undefined,
          [theme.breakpoints.up('md')]: {
            width: home ? undefined : '50%',
            marginLeft: 'auto',
            alignItems: home ? 'center' : undefined,
          },
        }}
        open={showInstruction}
        onClick={() => setShowInstruction(!showInstruction)}
      >
        <Zoom in={showInstruction} unmountOnExit style={{ transformOrigin: 'top right' }}>
          <Card
            sx={{
              position: 'relative',
              maxWidth: { xs: '100%', md: 'min(calc(100% - 44px), 700px)' },
              borderRadius: 4,
              padding: { md: 4 },
              pt: 5,
            }}
          >
            <Button
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: 1,
                minWidth: 0,
              }}
            >
              <CloseIcon />
            </Button>

            <CardContentNoPadding sx={{ maxHeight: '75vh', overflowY: 'auto', padding: 0 }}>
              <Typography variant={'h4'} fontWeight={600} color={'#000'} mb={2}>
                {t('instruction.title')}
              </Typography>
              <Typography>
                <Trans
                  i18nKey="instruction.text"
                  components={{
                    br: <br />,
                  }}
                />
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, pt: 2 }}>
                {instructionImages.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column-reverse', md: 'row' },
                      columnGap: 2,
                      rowGap: 1,
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{}} component="img" src={item} alt="dekoratives Element" draggable="false"></Box>
                    <Typography>{t(`instruction.text_${index + 1}`)}</Typography>
                  </Box>
                ))}
              </Box>
            </CardContentNoPadding>
          </Card>
        </Zoom>
      </Backdrop>
    </Box>
  );
};

export default GameInstructions;
