export const fetchHighscoresQuery = `
query {
  highscores (sort: ["score:desc", "publishedAt:asc"]) {
    data {
      id
      attributes {
        name
        score
      }
    }
  }
}
`;

export const createHighscoreMutation = `
mutation CreateHighscore($data: HighscoreInput!) {
  createHighscore(data: $data) {
    data {
      id
      attributes {
        score
        name
      }
    }
  }
}
`;


export const fetchHighscoreSumQuery = `
query {
  highscoreSum {
    sum
  }
}
`;