import { FC, Fragment, useState } from 'react';
import { Box, Button, Link, Slide, Stack, Typography, useTheme } from '@mui/material';
import ButtonSnowBorder from 'src/components/christmasTheme/ButtonSnowBorder';
import Showplaces from 'src/components/showplaces/views/Showplaces';
import { Background, BackgroundChristmas, ChristmasHeading, BackgroundBottom } from './../assets/images';
import { christmas } from 'src/utils/christmasTime';
import { t } from 'i18next';
import { HeadingDecoration, LogoFooter } from 'src/components/decorativeElements';
import { Trans } from 'react-i18next';
import { DONATION_WEBSITE_URL, DOYO_WEBSITE_URL } from 'src/constants/specs';
import { resetShowplacesMin } from 'src/components/showplaces/reducer/showplacesSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { resetGeoCoordinates, setScorePoints } from 'src/app/slices/app';
import GameInstructions from 'src/components/showplaces/components/GameInstructions';

const Home: FC = () => {
  const { breakpoints } = useTheme();
  const [started, setStarted] = useState(false);

  const { data: showplacesList } = useAppSelector((state) => state.showplacesMin);
  const userTracking = useAppSelector((state) => state.app.geoCoordinates);
  const dispatch = useAppDispatch();
  const backToExistingGame = () => {
    setStarted(true);
  };

  const startGame = () => {
    dispatch(resetShowplacesMin());
    dispatch(resetGeoCoordinates());
    dispatch(setScorePoints(0));

    setStarted(true);
  };

  return (
    <Fragment>
      <Box
        sx={{
          height: '100%',
          backgroundImage: christmas()
            ? `url(${BackgroundChristmas})`
            : `linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),url(${Background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          transition: 'background',
        }}
      >
        {christmas() && (
          <img
            style={{ width: '100%', position: 'fixed', bottom: '0', pointerEvents: 'none' }}
            src={BackgroundBottom}
            draggable="false"
            alt="hohoho"
            loading="lazy"
          />
        )}
        <Slide direction="down" in={!started} mountOnEnter unmountOnExit onExited={() => setStarted(true)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              paddingBottom: started ? 0 : '120px',
              width: '100%',
            }}
            p={2}
          >
            {christmas() && (
              <img style={{ maxWidth: '100%' }} src={ChristmasHeading} alt="hohoho" loading="lazy" draggable="false" />
            )}

            <Stack spacing={2} maxWidth="500px" color="#fff" sx={{ textAlign: 'center', overflow: 'auto' }} my={2}>
              <Box>
                <HeadingDecoration>
                  <Typography variant="h5" sx={{ fontSize: '24px', lineHeight: '28px' }}>
                    {christmas() ? t('home.gameTitleChristmas') : t('home.gameTitle')}
                  </Typography>
                </HeadingDecoration>
              </Box>

              <Typography
                sx={{
                  fontSize: { md: '20px', xs: '16px' },
                  lineHeight: { md: '28px', xs: '24px' },
                }}
              >
                <Trans
                  i18nKey={christmas() ? 'home.gameIntroChristmas' : 'home.gameIntro'}
                  components={{
                    br: <br />,
                    link1: <Link href={DOYO_WEBSITE_URL} target="_blank" rel="noreferrer"></Link>,
                    link2: <Link href={DONATION_WEBSITE_URL} target="_blank" rel="noreferrer"></Link>,
                  }}
                />
              </Typography>
            </Stack>
            <Box
              mt={{ md: 5, xs: 2 }}
              display={'flex'}
              sx={{ columnGap: 2, rowGap: 2, flexWrap: 'wrap', justifyContent: 'center' }}
            >
              <ButtonSnowBorder>
                <Button
                  className="geo-button-start"
                  variant="contained"
                  onClick={startGame}
                  color="primary"
                  sx={{ textTransform: 'none', fontWeight: 'bold' }}
                >
                  {showplacesList.length > 1 && userTracking.length > 0 ? t('action.newGame') : t('action.letsGo')}
                </Button>
              </ButtonSnowBorder>
              {showplacesList.length > 1 && userTracking.length > 0 && (
                <ButtonSnowBorder topRight>
                  <Button
                    className="geo-button-start"
                    variant="contained"
                    onClick={backToExistingGame}
                    color="primary"
                    sx={{ textTransform: 'none', fontWeight: 'bold' }}
                  >
                    {t('action.backToExistingGame')}
                  </Button>
                </ButtonSnowBorder>
              )}
            </Box>
          </Box>
        </Slide>
        {started ? (
          <Showplaces roundTracking={userTracking.length}></Showplaces>
        ) : (
          <GameInstructions home></GameInstructions>
        )}
      </Box>

      <Box sx={{ position: 'fixed', bottom: '3%', left: '50%', transform: 'translate(-50%)' }}>
        <LogoFooter></LogoFooter>
      </Box>
    </Fragment>
  );
};

export default Home;
