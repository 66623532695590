import { createSlice } from '@reduxjs/toolkit'
import { GeoCoordinates } from 'src/typings/interfaces';


const initialState: { geoCoordinates: GeoCoordinates[], pinchGesture: boolean, points: number } = {
  geoCoordinates: [],
  pinchGesture: true,
  points: 0,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setGeoCoordinates: (
      state, action
    ) => {
      state.geoCoordinates.push(action.payload)
    },
    resetGeoCoordinates: (state) => {
      state.geoCoordinates = []
    },
    setPinchGesture: (state) => {
      state.pinchGesture = false;
    },
    setScorePoints: (state, action) => {
      state.points = action.payload
    }
  }
})

export const { setGeoCoordinates, resetGeoCoordinates, setPinchGesture, setScorePoints } =
  slice.actions

export default slice.reducer
