import { Box, TextField, TextFieldProps, Typography } from '@mui/material'
import React from 'react'
import { RegisterOptions, useFormContext, useWatch, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type TextInputProps = TextFieldProps & {
  formKey: string
  options?: RegisterOptions
  validationText?: string
  hideValidationText?: boolean
}

export const TextInput: React.FC<TextInputProps> = ({ formKey, options, hideValidationText, validationText, ...props }) => {
  const { t } = useTranslation()
  const { inputProps, type, defaultValue, sx, ...restProps } = props

  const { control, setValue } = useFormContext()

  const inputValue = useWatch({
    control,
    name: formKey
  })

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputProps?.maxLength && e.target.value.length > inputProps.maxLength) {
      return
    }

    const value = type === 'number' ? (Number(e.target.value) || '') : e.target.value

    setValue(formKey, value, { shouldValidate: true, shouldDirty: true })
  }

  return (
    <Controller
      name={formKey}
      control={control}
      rules={options}
      render={({ field: { value, ...restFields }, fieldState }) => (
        <>
          <TextField
            fullWidth
            {...restProps}
            {...restFields}
            sx={{
              ...sx
            }}
            inputProps={inputProps}
            type={type || 'text'}
            value={value ?? defaultValue ?? ''}
            onChange={handleOnChange}
            error={(fieldState.invalid && fieldState.isDirty) || (fieldState.invalid && value)}
          />
          {!hideValidationText && <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
          >
            <Typography variant="subtitle2"
              sx={{
                mt: 0.5,
                color: 'error.main',
                opacity: (fieldState.invalid && fieldState.isDirty) || ((fieldState.invalid && value)) ? 1 : 0
              }}
            >
              {validationText || t('error.input.default')}
            </Typography>
              {inputProps?.maxLength && type !== 'number' && <Typography variant="subtitle2"
                sx={{
                  mt: 0.5
                }}
              >
                {t('inputs.charactersRemaining', { count: inputProps.maxLength - (inputValue?.length || 0) })}
              </Typography>}
            </Box>
        }
        </>
      )
    }
    />
  )
}
