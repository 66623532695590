export const FULLSCREEN_IMAGE_TIME = 3000

export const MIN_POINTS_FOR_SCORE = 50

export const AMOUNT_DEFAULT = 10 // Rounds per Game

export const MAX_DISTANCE = 50 // in km for points

export const POINTS_IN_EURO = 3 / 1000 // 3 EUR for 1000 pts

export const DONATION_WEBSITE_URL = "https://www.kleinehelden-hospiz.de/"

export const DOYO_WEBSITE_URL = "https://getdoyo.app"