import { FC } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { IShowplaceSolution } from 'src/typings/interfaces';
import { styled } from '@mui/material/styles';
import theme from 'src/theme/index';

const CardContentNoPadding = styled(CardContent)(`
  &:last-child {
    padding-bottom: 0;
  }
`);

type Props = React.PropsWithChildren & {
  showplaceSolution: IShowplaceSolution | null;
};

const ShowplaceInfo: FC<Props> = (props) => {
  const { showplaceSolution } = props;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: { xs: '10px', md: 'auto' },
        bottom: { xs: 'auto', md: '5%' },
        left: 0,
        width: { xs: '100%', md: '50%' },
        zIndex: { xs: theme.zIndex.drawer, md: theme.zIndex.drawer + 3 },
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ maxWidth: { xs: 'calc(90% - 94px);', md: '80%' }, borderRadius: 4 }}>
        <CardContentNoPadding sx={{ maxHeight: '50vh', overflowY: 'auto', padding: 0 }}>
          <Typography variant="h5" component="div" fontWeight={'600'} textAlign={'center'}>
            {showplaceSolution?.attributes.title}
          </Typography>
          <Typography variant="body2">{showplaceSolution?.attributes.description}</Typography>
        </CardContentNoPadding>
      </Card>
    </Box>
  );
};

export default ShowplaceInfo;
