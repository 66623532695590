// available fonts
// ["Varela Round","Poppins","Ubuntu","Nunito"]
import 'src/fonts/fonts.css';
/*
declare module '@mui/material/styles' {
  interface TypographyVariants {
    textMedium: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
  }
}*/

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    textMedium: true;
  }
}

const typography = {
  fontFamily: [
    'Outfit',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'
  ].join(','),
  "fontWeightLight": 300,
  "fontWeightRegular": 400,
  "fontWeightMedium": 600,
  textMedium: {
    fontSize: '20px',
  }
};

export default typography;
