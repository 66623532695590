export const fetchShowplacesQuery = `
query {
  showplaces {
    data {
      id
      attributes {
        title
        description
        geo
        images {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`;

export const fetchShowplacesMinQuery = `
query showplacesRandom ($amount: Int){
  showplacesRandom (amount: $amount) {
    showplaces {
      data {
        id
        attributes {
          geo
          images {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
}
`;

export const fetchShowplaceByIdQuery = `
query showplace($id: ID!){
  showplace(id: $id) {
    data {
      id
      attributes {
        title
        description
        geo
        images {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`;

export const createShowplaceMutation = `
mutation CreateShowplace($input: CreateShowplaceInput!) {
  createShowplace(createShowplaceInput: $input) {
    id
    name
    description
    imageUrl
    geo
    tags
  }
}
`;

export const updateShowplaceMutation = `
mutation UpdateShowplace($input: UpdateShowplaceInput!) {
  updateShowplace(updateShowplaceInput: $input) {
    id
    name
    description
    imageUrl
    geo
    tags
  }
}
`;

export const deleteShowplaceMutation = `
mutation removeShowplace($id: String!) {
  removeShowplace(id: $id) {
    id
  }
}
`;
