import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createHighscoreAPI, fetchHighscoresAPI } from 'src/api/graphql/highscores.API';
import { HighscoreState, ICreateHighscore } from 'src/typings/interfaces/highscore.interfaces';

export const fetchHighscoresAsync = createAsyncThunk('highscores/fetchHighscores', async () => {
  return await fetchHighscoresAPI();
});

export const createHighscoreAsync = createAsyncThunk('highscores/createHighscore', async (newHighscore: ICreateHighscore) => {
  return await createHighscoreAPI(newHighscore)
});

const initialState: HighscoreState = {
  data: [],
  status: 'idle',
  error: null,
};

export const highscoresSlice = createSlice({
  name: 'highscore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHighscoresAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHighscoresAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchHighscoresAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createHighscoreAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createHighscoreAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.push(action.payload.data);
      })
      .addCase(createHighscoreAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export default highscoresSlice.reducer;
