import { FC, Fragment } from 'react';
import { Box } from '@mui/material';
import { TextInput } from 'src/components/inputs/TextInput';
import palette from 'src/theme/palette';
import { t } from 'i18next';
import { christmas } from 'src/utils/christmasTime';

const HighscoreInput: FC = () => {
  return (
    <Fragment>
      <Box
        sx={{
          '& h6': { color: christmas() ? 'white' : 'inherit', textAlign: 'left', fontWeight: '300' },
        }}
      >
        <TextInput
          size="small"
          id="outlined-basic"
          label="Name"
          variant="filled"
          formKey="name"
          color="secondary"
          options={{
            required: true,
          }}
          inputProps={{ maxLength: 30 }}
          validationText={t('error.input.invalidName')}
          sx={{
            input: {
              color: palette.black,
              backgroundColor: '#F3F3F3',
            },
          }}
        />
        <Box sx={{ display: 'none' }}>
          <TextInput id="honey" formKey="bot" type="text" />
        </Box>
      </Box>
    </Fragment>
  );
};

export default HighscoreInput;
