import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import typography from './typography';
import palette from './palette';
import { buttonClasses } from '@mui/material/Button';
import { toggleButtonClasses } from '@mui/material/ToggleButton';

const borderRadius = '0.3rem';
const padding = '1rem';

const defaultTheme = createTheme();
const theme = createTheme({
  typography,
  palette,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%'
        },
        body: {
          overflow: 'hidden',
          height: '100%',
          userSelect: 'none',
          touchAction: 'pan-x pan-y'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        color: 'primary'
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '16px',
          [`&.${buttonClasses.disabled}`]: {
            opacity: 0.5
          },
          boxShadow: 'none',
          // Fix ButtonGroup disabled styles.
          [`&.${toggleButtonClasses.root}.${buttonClasses.disabled}`]: {
            color: defaultTheme.palette.action.disabled,
            borderColor: defaultTheme.palette.action.disabledBackground
          }
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '1rem',
          width: '100%',
        },
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          touchAction: 'pan-x pan-y',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        position: 'static',
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'inherit',
        variant: 'body1',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'medium',
        color: 'inherit',
      },
    },
    MuiStack: {
      defaultProps: {
        sx: {
          backgroundColor: 'white',
          borderRadius,
          padding,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          '&::before, &::after': {
            left: '12px !important',
            right: '12px !important'
          }
        },
        input: {
          borderRadius: 16,
          color: palette.black,
          backgroundColor: '#F3F3F3',
        }
      }
    }
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default responsiveFontSizes(theme);
