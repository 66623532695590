import { FC, Fragment } from 'react';
import { Marker } from 'react-leaflet';
import { LatLngLiteral, divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import PlaceIcon from '@mui/icons-material/Place';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { MAX_DISTANCE } from 'src/constants/specs';

type Props = React.PropsWithChildren & {
  correctPos: LatLngLiteral;
  distance: number | null | undefined;
};

const CorrectLocationMarker: FC<Props> = (props) => {
  const { correctPos, distance } = props;

  const theme = useTheme();
  const maxDistanceInM = MAX_DISTANCE * 1000;
  const iconSize = 56;
  const color = distance && distance <= maxDistanceInM ? theme.palette.success.main : theme.palette.error.main;

  const iconMarkup = renderToStaticMarkup(<PlaceIcon style={{ fill: color }} sx={{ fontSize: iconSize }} />);

  const customMarkerIcon = divIcon({
    html: `<div>${iconMarkup}</div>`,
    iconSize: [iconSize, iconSize],
    iconAnchor: [iconSize / 2, iconSize],
    className: 'correct-marker',
  });

  return (
    <Fragment>
      <Box>
        <Marker icon={customMarkerIcon} position={correctPos}></Marker>
      </Box>
    </Fragment>
  );
};

export default CorrectLocationMarker;
