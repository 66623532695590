import { FC, Fragment, useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { MapContainer, Marker, Polyline, TileLayer, useMapEvents } from 'react-leaflet';
import { LatLngBoundsExpression, LatLngExpression, LatLngLiteral, Map, divIcon } from 'leaflet';
import CorrectLocationMarker from './components/CorrectLocationMarker';
import InfoBar from './components/InfoBar';
import LocationMarker from './components/LocationMarker';
import { renderToStaticMarkup } from 'react-dom/server';
import theme from 'src/theme/index';
import { MAX_DISTANCE } from 'src/constants/specs';
import Lottie from 'lottie-react';
import { Spread } from 'src/assets/images';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { setPinchGesture, setScorePoints } from 'src/app/slices/app';

type Props = React.PropsWithChildren & {
  correctPos: string;
  imageButtonClicked: () => void;
  imageUrl: string;
  onNext: () => void;
  onGuess: (position: any) => void;
  finished: boolean;
  progress: { round: number | undefined; roundMax: number | undefined };
};

const LeafletMap: FC<Props> = (props) => {
  const { correctPos, imageUrl, onNext, finished, progress, imageButtonClicked, onGuess } = props;

  const [position, setPosition] = useState<LatLngLiteral | null>(null);
  const [correctPosition, setCorrectPosition] = useState<LatLngLiteral | null>(null);
  const [map, setMap] = useState<Map | null>(null);
  const [checked, setChecked] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [distance, setDistance] = useState<number | null | undefined>(null);
  const points = useAppSelector((state) => state.app.points);

  const dispatch = useAppDispatch();

  const { pinchGesture } = useAppSelector(({ app }) => app);

  const mapCenterDisplayed = { lat: 50.45908750431791, lng: 10.205768796356363 };
  const imageOverlayBounds = [
    [56.06, 3.56],
    [45.182, 16.633],
  ] as LatLngBoundsExpression;
  const mapZoom = 6;

  const MapHelper = () => {
    useMapEvents({
      click(e) {
        dispatch(setPinchGesture());
        if (checked || finished || animating) {
          return;
        }
        setPosition(e.latlng);
      },
      movestart() {
        dispatch(setPinchGesture());
      },
    });
    return null;
  };

  const calcScore = (d: number) => {
    const dInKm = d / 1000;

    let score = 0;

    const maxDistance = MAX_DISTANCE;
    const minDistanceForLinear = 1;
    const minScore = 100;

    if (dInKm <= minDistanceForLinear) {
      score = minScore;
    } else if (dInKm <= maxDistance) {
      const scoreCalc = minScore * (1 - (dInKm - minDistanceForLinear) / (maxDistance - minDistanceForLinear));
      score = Math.round(Math.max(0, Math.min(minScore, scoreCalc)));
    }
    dispatch(setScorePoints(points + score));
  };

  const distanceDisplayed = useMemo(() => {
    if (!distance) {
      return;
    }
    const distanceRounded = Math.trunc(distance);
    const formatted = distanceRounded > 1000 ? `${Math.trunc(distanceRounded / 1000)} km` : `${distanceRounded} m`;
    return formatted;
  }, [distance]);

  const distanceText = renderToStaticMarkup(
    <Typography
      variant={'caption'}
      style={{
        whiteSpace: 'nowrap',
        background: '#fff',
        backgroundColor: '#fff',
        borderRadius: theme.spacing(3),
        padding: '10px',
      }}
    >
      {distanceDisplayed}
    </Typography>,
  );
  const textDistance = divIcon({ html: `<div style='position: absolute'>${distanceText}</div>`, className: '' });

  const handleGuess = () => {
    if (!position) {
      return;
    }
    setAnimating(true);
    const pos = correctPos.split(',');
    const correct = { lat: parseFloat(pos[0]), lng: parseFloat(pos[1]) };
    setCorrectPosition(correct);
    const markers = [
      [position?.lat, position?.lng],
      [correct?.lat, correct?.lng],
    ];

    map?.flyToBounds(markers as LatLngBoundsExpression, { padding: [50, 50] }).once('zoomend', () => {
      setChecked(true);
      setAnimating(false);
    });

    const d = map?.distance(position as LatLngExpression, correct as LatLngExpression);
    setDistance(d);
    if (!d) return;
    calcScore(d);
    onGuess(position);
  };

  const handleNext = () => {
    setDistance(null);
    setChecked(false);
    setPosition(null);
    map?.setView(mapCenterDisplayed, mapZoom);
    onNext();
  };

  return (
    <Fragment>
      <Box sx={{ position: 'relative', height: '100%' }}>
        <MapContainer
          center={mapCenterDisplayed}
          zoom={mapZoom}
          scrollWheelZoom={true}
          style={{ height: '100%' }}
          ref={setMap}
          maxBounds={imageOverlayBounds}
          maxBoundsViscosity={1}
          minZoom={mapZoom}
        >
          <TileLayer
            className=""
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* <ImageOverlay url={MapOverlay} bounds={imageOverlayBounds} opacity={0.5} /> */}
          <MapHelper />

          {position && <LocationMarker position={position} imageUrl={imageUrl}></LocationMarker>}

          {checked && correctPosition && position && (
            <>
              <CorrectLocationMarker correctPos={correctPosition} distance={distance} />
              <Marker
                position={{
                  lat: (correctPosition.lat + position.lat) / 2,
                  lng: (correctPosition.lng + position.lng) / 2,
                }}
                icon={textDistance}
              />
              <Polyline
                positions={[correctPosition, position]}
                color={theme.palette.secondary.main}
                weight={2}
                dashArray={'10'}
              />
            </>
          )}
        </MapContainer>
        {pinchGesture && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              zIndex: theme.zIndex.drawer + 1,
              pointerEvents: 'none',
            }}
            display={{ xs: 'block', md: 'none' }}
          >
            <Lottie loop={true} animationData={Spread} />
          </Box>
        )}

        <InfoBar
          onNext={handleNext}
          onGuess={handleGuess}
          imageButtonClicked={imageButtonClicked}
          imageUrl={imageUrl}
          animating={animating}
          progress={progress}
          points={points}
          positioned={!!position}
          checked={checked}
        ></InfoBar>
      </Box>
    </Fragment>
  );
};

export default LeafletMap;
