import { DoyoLogo, DoyoLogoGreen, KleineHeldenLogo } from 'src/assets/images';
import { Box } from '@mui/material';
import { FC } from 'react';
import { DOYO_WEBSITE_URL, DONATION_WEBSITE_URL } from 'src/constants/specs';
import { christmas } from 'src/utils/christmasTime';

const LogoFooter: FC = () => {
  return (
    <Box>
      {christmas() ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: '20px' }}>
          <a href={DOYO_WEBSITE_URL} target="_blank" rel="noreferrer">
            <Box sx={{ height: '54px' }} component="img" src={DoyoLogo} alt="doyo logo" draggable="false"></Box>
          </a>
          <Box sx={{ flexShrink: 0, width: '1px', backgroundColor: '#fff', opacity: 0.3 }}></Box>
          <a href={DONATION_WEBSITE_URL} target="_blank" rel="noreferrer">
            <Box
              sx={{ height: '54px' }}
              component="img"
              src={KleineHeldenLogo}
              alt="logo Kleine Helden Hünfeld"
              draggable="false"
            ></Box>
          </a>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: '20px' }}>
          <a href={DOYO_WEBSITE_URL} target="_blank" rel="noreferrer">
            <Box sx={{ height: '54px' }} component="img" src={DoyoLogoGreen} alt="doyo logo" draggable="false"></Box>
          </a>
        </Box>
      )}
    </Box>
  );
};

export default LogoFooter;
