import { FC, Fragment, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import palette from 'src/theme/palette';
import { Schade } from 'src/assets/images';
import HighscoreInput from '../components/HighscoreInput';
import HeadingDecoration from 'src/components/decorativeElements/HeadingDecoration';
import { ICreateHighscore } from '@typings/interfaces';
import ButtonSnowBorder from 'src/components/christmasTheme/ButtonSnowBorder';
import { t } from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { numberDotToComma, pointsToMoney } from 'src/utils/format';
import CountUp from 'react-countup';
import { PresentLottie, Glueckwunsch } from 'src/assets/images';
import Lottie from 'lottie-react';
import { createHighscoreAsync, fetchHighscoresAsync } from '../reducer/highscoresSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { MIN_POINTS_FOR_SCORE } from 'src/constants/specs';
import { LoadingButton } from '@mui/lab';
import { christmas } from 'src/utils/christmasTime';

type Props = React.PropsWithChildren & {
  onSuccess: (id: string) => void;
  onPlayAgain: () => void;
};

const HighscoreSubmitView: FC<Props> = (props) => {
  const { onSuccess, onPlayAgain } = props;
  const points = useAppSelector((state) => state.app.points);

  const [submitting, setSubmitting] = useState(false);

  const dispatch = useAppDispatch();
  const { geoCoordinates } = useAppSelector((state) => state.app);

  const formData = useForm<ICreateHighscore>({
    mode: 'onChange',
    defaultValues: {
      bot: '',
      score: 0,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = formData;

  const submit = handleSubmit(async (input) => {
    setSubmitting(true);

    const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const data = { ...input, score: 0, publishedAt: now, geoCoordinates };

    dispatch(createHighscoreAsync(data))
      .then((res) => {
        dispatch(fetchHighscoresAsync());

        onSuccess(res.payload.data.id);

        setSubmitting(false);
        reset();
      })
      .catch(() => {
        setSubmitting(false);
      });
  });

  return (
    <Fragment>
      {christmas() ? (
        <>
          {points >= MIN_POINTS_FOR_SCORE ? (
            <Box sx={{ marginTop: { md: -14, xs: -6 }, marginBottom: { xs: -3, md: -10 }, maxWidth: '700px' }}>
              <Lottie loop={true} animationData={Glueckwunsch} />
            </Box>
          ) : (
            <>
              <img style={{ maxWidth: '100%' }} src={Schade} alt="hohoho" loading="lazy" draggable="false" />
            </>
          )}
          <Box
            textAlign={'center'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 2,
              backgroundColor: 'transparent',
              overflow: 'hidden',
            }}
          >
            <Box>
              <HeadingDecoration>
                <Typography fontSize={'24px'} fontWeight={300} color={palette.primary.contrastText}>
                  <Trans
                    i18nKey="highscore.reachedPoints"
                    values={{
                      points: numberDotToComma(points),
                    }}
                    components={{
                      b: <b />,
                    }}
                  />
                </Typography>
              </HeadingDecoration>
              {points >= MIN_POINTS_FOR_SCORE && (
                <Box display={'flex'} justifyContent={'center'} alignItems={'flex-end'} columnGap={0}>
                  <Box width={{ xs: '40%', md: 'auto' }}>
                    <Lottie loop={true} animationData={PresentLottie} />
                  </Box>
                  <Box color={palette.primary.contrastText} textAlign={'left'} mb={'20px'}>
                    <Typography sx={{ fontSize: '48px', whiteSpace: 'nowrap' }}>
                      <CountUp start={0} end={pointsToMoney(points)} decimal="," decimals={2} separator="," /> €
                    </Typography>

                    <Typography variant="textMedium">{t('highscore.reachedPointsInMoney')}</Typography>
                  </Box>
                </Box>
              )}
            </Box>
            {points >= MIN_POINTS_FOR_SCORE ? (
              <>
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', overflowY: 'auto', minHeight: '5vh' }}
                  mt={{ xs: -2, md: 0 }}
                >
                  <Typography
                    color={palette.primary.contrastText}
                    fontSize={'20px'}
                    fontWeight={300}
                    maxWidth={'500px'}
                  >
                    {t('highscore.commitYourselfChristmas')}
                  </Typography>
                </Box>

                <FormProvider {...formData}>
                  <form>
                    <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: 2 }}>
                      <HighscoreInput></HighscoreInput>

                      <Box mt={'9px'}>
                        <ButtonSnowBorder>
                          <LoadingButton
                            className="geo-button-start"
                            variant="contained"
                            color="primary"
                            onClick={submit}
                            sx={{ textTransform: 'none', fontWeight: 'bold' }}
                            type="submit"
                            loading={submitting}
                            disabled={!isDirty || !isValid}
                          >
                            {t('action.enter')}
                          </LoadingButton>
                        </ButtonSnowBorder>
                      </Box>
                    </Box>
                  </form>
                </FormProvider>
              </>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonSnowBorder>
                  <Button
                    className="geo-button-start"
                    variant="contained"
                    color="primary"
                    onClick={onPlayAgain}
                    sx={{ textTransform: 'none', fontWeight: 'bold', marginBottom: '25px' }}
                    type="submit"
                  >
                    {t('action.playAgain')}
                  </Button>
                </ButtonSnowBorder>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box
          textAlign={'center'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 2,
            backgroundColor: 'transparent',
            overflow: 'hidden',
            color: '#7A7A7A',
          }}
        >
          {points >= MIN_POINTS_FOR_SCORE ? (
            <>
              <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center', justifyContent: 'center' }}>
                <svg width="20" height="45" viewBox="0 0 20 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 23.5167L11.5174 22.9361" stroke="#2BBD6E" strokeWidth="5" strokeLinecap="round" />
                  <path
                    d="M16.0252 9.62466C15.4048 9.48768 10.1823 3.82062 9.89536 3.38706"
                    stroke="#2BBD6E"
                    strokeWidth="5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M16.7824 36.6839C16.6126 37.2614 10.3828 42.0064 9.91063 42.2639"
                    stroke="#2BBD6E"
                    strokeWidth="5"
                    strokeLinecap="round"
                  />
                </svg>

                <Typography fontSize={'48px'} lineHeight={1} fontWeight={600} color="#000">
                  {t('highscore.success')}
                </Typography>
                <svg width="20" height="45" viewBox="0 0 20 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.9727 21.7473L8.45524 22.3278" stroke="#2BBD6E" strokeWidth="5" strokeLinecap="round" />
                  <path
                    d="M3.94745 35.6393C4.56782 35.7762 9.79034 41.4433 10.0773 41.8769"
                    stroke="#2BBD6E"
                    strokeWidth="5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M3.19023 8.57999C3.36008 8.00253 9.58981 3.25755 10.062 2.99999"
                    stroke="#2BBD6E"
                    strokeWidth="5"
                    strokeLinecap="round"
                  />
                </svg>
              </Box>
              <Box>
                <Box sx={{ position: 'relative', display: 'inline' }}>
                  <Typography sx={{ fontSize: '24px', fontWeight: 600, display: 'inline' }}>
                    {points} {t('common.points')}
                  </Typography>
                  <Box sx={{ position: 'absolute', width: '100%', left: 0, bottom: 0, transform: 'translateY(50%)' }}>
                    <svg width="100%" height="auto" viewBox="0 0 127 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.04682 8.75232C4.6477 8.56249 7.3326 7.80123 9.85084 7.34992C23.1343 4.9693 36.7765 3.83975 50.4085 3.15429C75.1097 1.91223 100.651 1.86227 125.045 5.58937"
                        stroke="#2BBD6E"
                        strokeWidth="3"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{ display: 'flex', justifyContent: 'center', overflowY: 'auto', minHeight: '5vh' }}
                mt={{ xs: -2, md: 0 }}
              >
                <Typography fontSize={'20px'} maxWidth={'500px'}>
                  {t('highscore.commitYourself')}
                </Typography>
              </Box>

              <FormProvider {...formData}>
                <form>
                  <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: 2 }}>
                    <HighscoreInput></HighscoreInput>

                    <Box mt={'9px'}>
                      <ButtonSnowBorder>
                        <LoadingButton
                          className="geo-button-start"
                          variant="contained"
                          color="primary"
                          onClick={submit}
                          sx={{ textTransform: 'none', fontWeight: 'bold' }}
                          type="submit"
                          loading={submitting}
                          disabled={!isDirty || !isValid}
                        >
                          {t('action.enter')}
                        </LoadingButton>
                      </ButtonSnowBorder>
                    </Box>
                  </Box>
                </form>
              </FormProvider>
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                rowGap: 3,
              }}
            >
              <Box>
                <Typography fontSize={'48px'} lineHeight={1} fontWeight={600} color="#000">
                  {t('highscore.fail')}
                </Typography>
              </Box>
              <ButtonSnowBorder>
                <Button
                  className="geo-button-start"
                  variant="contained"
                  color="primary"
                  onClick={onPlayAgain}
                  sx={{ textTransform: 'none', fontWeight: 'bold', marginBottom: '25px' }}
                  type="submit"
                >
                  {t('action.playAgain')}
                </Button>
              </ButtonSnowBorder>
            </Box>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default HighscoreSubmitView;
